import { Injectable } from '@angular/core';

import { LoginContextService } from '@mpk/shared/data-access';

@Injectable({ providedIn: 'root' })
export class HangfireFacade {
  constructor(private readonly loginContextService: LoginContextService) {}

  getHangfireUrl(jobId?: string): string | undefined {
    const accessToken: string = this.loginContextService.accessToken();

    const link = new URL(self.location.origin);
    link.pathname = jobId ? `/jobs/jobs/details/${jobId}` : '/jobs';
    link.searchParams.set('t', accessToken);
    link.searchParams.set('x-active-organization', this.loginContextService.organizationContext().organizationId);

    return link.toString();
  }
}
