import { Injectable } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { Observable, concatMap } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { LoginContextService } from '@mpk/shared/data-access';

import { ConfigLoaderFacade } from './config-loader.facade';
import { AppInsightsConfig, EnvironmentConfig } from './models';

@Injectable()
export class AppConfigService {
  readonly envConfig$: Observable<EnvironmentConfig> = this.configLoaderFacade.environmentConfig$;

  readonly appInsightsConfig$: Observable<AppInsightsConfig> =
    // For security reasons app insights config should not be downloaded before user is logged in
    toObservable(this.loginContextService.isUserLoggedIn).pipe(
      concatMap(() => this.configLoaderFacade.appInsightsConfig$),
      shareReplay(1),
    );

  constructor(
    private readonly configLoaderFacade: ConfigLoaderFacade,
    private readonly loginContextService: LoginContextService,
  ) {}
}
