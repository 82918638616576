import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ToastUrgency } from '@core/shared/util';

import { ErrorNotificationService } from './error-notification.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(private readonly notificationService: ErrorNotificationService) {}

  checkError(error: HttpErrorResponse): void {
    if (!navigator.onLine) {
      this.notificationService.showError('Es besteht keine Internetverbindung.');
    }

    //TODO: Needs to be refactored when adjusting errorhandling, new method showErrorCustom is for this purpose only
    if (error.error.errors?.['']?.[0] === 'SolrAliveError') {
      this.notificationService.showErrorCustom(
        'Der Suchserver ist aktuell nicht erreichbar... versuche es bitte in wenigen Minuten erneut',
        ToastUrgency.Warning,
      );
      return;
    }

    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 401:
          this.notificationService.showError('Du bist nicht angemeldet. Bitte anmelden.');
          break;

        case 403:
          this.notificationService.showError('Du bist für diese Anfrage nicht autorisiert. (Status 403)');
          break;

        case 404:
          this.notificationService.showError('Die Seite wurde nicht gefunden. (Status 404)');
          break;

        case 405:
          this.notificationService.showError('Die Anfrage wird nicht unterstützt. (Status 405)');
          break;

        case 408:
          this.notificationService.showError(
            'Die Anfrage hat zu lange gedauert. Bitte versuche es später erneut. (Status 408)',
          );
          break;

        case 500:
          this.notificationService.showError('Die Anfrage konnte nicht verarbeitet werden. (Status 500)');
          break;

        case 504:
          this.notificationService.showError(
            'Der Server braucht für eine Antwort zu lange. Bitte versuche es später erneut. (Status 504)',
          );
          break;

        default:
          this.notificationService.showError(
            'Die Anfrage konnte nicht verarbeitet werden. (Status ' + error.status + ')',
          );
      }
    }
  }
}
