import { Injectable } from '@angular/core';

import { LoginContextService } from '@mpk/shared/data-access';

/**
 * Service to get API urls, optionally with authorization information.
 */
@Injectable({
  providedIn: 'root',
})
export class ApiUrlService {
  constructor(private readonly loginContextService: LoginContextService) {}

  getApiUrl(path: string, includeAuthorization = false): string {
    const link = new URL(path, self.location.origin);

    if (!link.pathname.startsWith('/api')) {
      link.pathname = `/api${path}`;
    }

    if (includeAuthorization) {
      const accessToken = this.loginContextService.accessToken();

      link.searchParams.set('access_token', accessToken);
      link.searchParams.set('x-active-organization', this.loginContextService.organizationContext().organizationId);
    }

    return link.toString();
  }
}
